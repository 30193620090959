export const ENV_VAR = {
  IFRAME_URL: "https://synthia.web-devapp.com", // PROD
  // IFRAME_URL: "https://synthia-s1ga.dev.vm.web-devapp.com", // DEV
  // IFRAME_URL: "https://synthia-ms-demo.dev.vm.web-devapp.com", // MVC
};

export const ERRORS = {
  ITEM_NOT_FOUND: "ItemNotFound",
};

export const MS_WORD_PARAMS = {
  CHANGE_TRACKING_MODE: "changeTrackingMode",
  TEXT: "text",
  ITEMS: "items",
  PARAGRAPHS: "paragraphs",
  ITEMS_TEXT: "items/text",
};

export const PLAT_FORM = {
  WEB: "OfficeOnline",
  PC: "PC",
  Mac: "Mac",
  iOS: "iOS",
  Android: "Android",
  Universal: "Universal",
};
