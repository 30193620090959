import showdown from "showdown";
import {
  acceptAllTheTrackedChangesByUserName,
  acceptAllTrackedChanges,
  acceptTrackedChangesInSelectionByUserName,
  findWordInDocument,
  getCommentsUsersList,
  getFileName,
  getNumOfCommentsByUsers,
  getNumOfTrackedChangesByUsers,
  getPageContext,
  getSelectedText,
  getTrackedUsersList,
  highlightAllOccurrenceOfText,
  highlightText,
  highlightTextWithDuration,
  insertAfterCursor,
  moveChangesWithTrackOn,
  rejectAllTheTrackedChangesByUserName,
  rejectAllTrackedChanges,
  rejectTrackedChangesInSelectionByUserName,
  replaceSelection,
  resolveAllComments,
  resolveCommentInSelectionByUserName,
  setTrackChanges,
  uploadFileToServer,
  scrollToParagraph,
  // replaceAllInstances,
  processReplacements,
} from "./AIB";

export const sendDataToSynthia = (ref: any, aibResponse: any) => {
  console.log("----Sending message to Synthia----", aibResponse);
  ref.current.contentWindow.postMessage(aibResponse, "*");
};

export const dynamicFunctionCaller = async (action) => {
  const { name, params } = action;
  const methodMap = {
    getPageContext,
    findWordInDocument,
    setTrackChanges,
    moveChangesWithTrackOn,
    getFileName,
    getTrackedUsersList,
    getNumOfTrackedChangesByUsers,
    acceptAllTrackedChanges,
    acceptAllTheTrackedChangesByUserName,
    acceptTrackedChangesInSelectionByUserName,
    rejectAllTrackedChanges,
    rejectAllTheTrackedChangesByUserName,
    rejectTrackedChangesInSelectionByUserName,
    highlightText,
    highlightAllOccurrenceOfText,
    highlightTextWithDuration,
    resolveAllComments,
    resolveCommentInSelectionByUserName,
    getCommentsUsersList,
    getNumOfCommentsByUsers,
    insertAfterCursor,
    replaceSelection,
    uploadFileToServer,
    getSelectedText,
    scrollToParagraph,
    // replaceAllInstances,
    processReplacements,
  };
  const funcToCall = methodMap[name];
  if (funcToCall) {
    return await funcToCall(params);
  } else {
    return false;
  }
};

export const printUtilErrorOnConsole = (utilName: string, error: OfficeExtension.Error) => {
  console.error(`Error in ${utilName}` + error);
  if (error instanceof OfficeExtension.Error) {
    console.error(`Debug info ${utilName}: ` + JSON.stringify(error.debugInfo));
  }
};

export const markdownToHtml = (markDown) => {
  const converter = new showdown.Converter();
  const md = markDown;
  const htmlContent = converter.makeHtml(md);
  return htmlContent;
};

const findLCS = (X, Y) => {
  const m = X.length;
  const n = Y.length;
  const L = Array.from({ length: m + 1 }, () => Array(n + 1).fill(0));

  for (let i = 0; i <= m; i++) {
    for (let j = 0; j <= n; j++) {
      if (i === 0 || j === 0) {
        L[i][j] = 0;
      } else if (X[i - 1] === Y[j - 1]) {
        L[i][j] = L[i - 1][j - 1] + 1;
      } else {
        L[i][j] = Math.max(L[i - 1][j], L[i][j - 1]);
      }
    }
  }
  return L;
};

const backtrackLCS = (L, X, Y, i, j) => {
  if (i === 0 || j === 0) {
    return [];
  } else if (X[i - 1] === Y[j - 1]) {
    return backtrackLCS(L, X, Y, i - 1, j - 1).concat(X[i - 1]);
  } else if (L[i - 1][j] > L[i][j - 1]) {
    return backtrackLCS(L, X, Y, i - 1, j);
  } else {
    return backtrackLCS(L, X, Y, i, j - 1);
  }
};

// const normalizeWord = (word) => word.trim();

const areWordsEquivalent = (word1, word2) => {
  const stripped1 = word1.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "");
  const stripped2 = word2.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "");
  return stripped1 === stripped2;
};

export const findDifferences = async (currentParagraph, suggestedParagraph, contextLength = 2) => {
  const words1 = currentParagraph.split(" ");
  const words2 = suggestedParagraph.split(" ");
  // const normalizedWords1 = words1.map(normalizeWord);
  // const normalizedWords2 = words2.map(normalizeWord);
  const L = findLCS(words1, words2);
  const lcs = backtrackLCS(L, words1, words2, words1.length, words2.length);
  // const L = findLCS(normalizedWords1, normalizedWords2);
  // const lcs = backtrackLCS(L, normalizedWords1, normalizedWords2, normalizedWords1.length, normalizedWords2.length);

  let i = 0,
    j = 0;
  const changes = [];

  const getContext = (words, index, length, contextLength) => {
    const start = Math.max(0, index - contextLength);
    const end = Math.min(words.length, index + length + contextLength);
    const contextWords = words.slice(start, index + 1);
    const originalIndex = index - start;
    if (originalIndex >= 0 && originalIndex < contextWords.length) {
      contextWords[originalIndex] = `##${`${contextWords[originalIndex]} ${words
        .slice(index + 1, end)
        .join(" ")}`.trim()}##`;
    }
    return contextWords.join(" ");
  };

  for (const word of lcs) {
    const originalWords = [];
    const replacementWords = [];

    // while (words1[i] !== word) {
    while (i < words1.length && !areWordsEquivalent(words1[i], word)) {
      originalWords.push(words1[i]);
      i++;
    }
    // while (words2[j] !== word) {
    while (j < words2.length && !areWordsEquivalent(words2[j], word)) {
      replacementWords.push(words2[j]);
      j++;
    }

    if (originalWords.length || replacementWords.length) {
      changes.push({
        context: getContext(words1, i, originalWords.length, contextLength),
        original: originalWords.join(" "),
        replacedBy: replacementWords.join(" ").trim(),
      });
    }

    i++;
    j++;
  }

  if (i < words1.length || j < words2.length) {
    changes.push({
      context: getContext(words1, i, words1.length - i, contextLength),
      original: words1.slice(i).join(" "),
      replacedBy: words2.slice(j).join(" ").trim(),
    });
  }

  return changes;
};

export const generateRandomId = () => {
  return Math.floor(100000 + Math.random() * 900000);
};
