import * as React from "react";
import { ENV_VAR } from "../../constants";
import { dynamicFunctionCaller, sendDataToSynthia } from "../../utils";
import { actionToCall } from "../../services/DemoService";

const App = () => {
  Office.initialize = () => {
    // Office is ready
  };
  const iframeRef = React.useRef(null);
  const processActions = async (data) => {
    const result = await dynamicFunctionCaller(data);
    let stackReference = [];
    if (data.reference && data.reference.length > 0) {
      stackReference = data.reference.splice(1);
      const stackReferenceLastIndex = stackReference.length - 1;
      const answerTemplate = {
        [data.name]: result,
      };
      stackReference[stackReferenceLastIndex].stackAnswer = [
        ...stackReference[stackReference.length - 1].stackAnswer,
        answerTemplate,
      ];
    }
    sendDataToSynthia(iframeRef, { action: data.name, data: result, reference: stackReference });
    // sendDataToSynthia(iframeRef, { action: data.name, data: result });
  };
  const handleMessage = async (event: MessageEvent) => {
    // console.log("message received from Synthia", event.data);
    if (event.origin === ENV_VAR.IFRAME_URL) {
      const main = event.data;
      processActions(main);
    }
  };

  React.useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <>
      <div id="app-container" style={{ height: "100vh" }}>
        {/* <button onClick={() => processActions(actionToCall)}>click Test</button> */}
        <iframe
          src={ENV_VAR.IFRAME_URL + "/dashboard"}
          width="100%"
          height="100%"
          ref={iframeRef}
          style={{ border: "none" }}
          title="synthia addin"
        ></iframe>
      </div>
    </>
  );
};

export default App;
